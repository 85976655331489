import styles from './navbar.module.scss';

export const Navbar = () => {
  return (<div className={styles.navbar}>
    <a href="#" className={styles.link}>Портфолио</a>
    <a href="#" className={styles.link}>Информация</a>
    <a href="#" className={styles.logo}>Alla Dami</a>
    <a href="#" className={styles.link}>Обо мне</a>
    <a href="#" className={styles.link}>Контакты</a>
  </div>)
}
