import styles from './hero.module.scss';

export const Hero = () => {
  return (<div className={styles.hero}>
    <img src="/images/i1.jpeg" className={styles.first_image} />
    <img src="/images/i2.jpeg" className={styles.second_image} />

    <div className={styles.wrapper}>
      <div className={styles.header_p1}>Фотография</div>
      <div className={styles.header_p2}>как искусство</div>
    </div>

    <div className={styles.portfolio_link}>
      <span>Портфолио</span>
      <img src="/images/link.svg" />
    </div>

    <div className={styles.socials}>
      <a className={styles.socials__link}>Instagram</a>
      <a className={styles.socials__link}>Telegram</a>
    </div>
  </div>)
}
